<script setup lang="ts">
import { useChangeApi } from '~/composables/useChangeApi'
import { showToastError } from '~/composables/useErrorToast'

const { stageApiInput, changeApi, open, loading, enableApiSwitcher }
  = useChangeApi()
</script>

<template>
  <NuxtLoadingIndicator :height="2" color="yellow" />
  <RErrorToast :is-visible="showToastError" />

  <NuxtLayout>
    <div class="overflow-x-hidden">
      <NuxtPage />
    </div>
    <div v-if="enableApiSwitcher">
      <RModal v-model="open">
        <RInput
          v-model:value="stageApiInput"
          label="Приложению нужно ~20 секунд чтобы перезапуститься"
          label-position="top"
          placeholder="feature-dev-999-test-test"
        >
          <r-link
            class="mt-1"
            to="https://gitlab.com/r3013/rang.ai-app/-/environments/"
          >
            https://gitlab.com/r3013/rang.ai-app/-/environments/
          </r-link>
        </RInput>
        <r-button :is-loading="loading" class="w-1/2 mt-4" @click="changeApi">
          Делать магию
        </r-button>
      </RModal>
    </div>
  </NuxtLayout>
</template>

<style lang="postcss">
html,
body,
#__nuxt {
  height: 100vh;
  margin: 0;
  padding: 0;
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.2s;
}
.slide-left-enter-from {
  opacity: 0;
  transform: translate(50px, 0);
}
.slide-left-leave-to {
  opacity: 0;
  transform: translate(-50px, 0);
}
.slide-right-enter-from {
  opacity: 0;
  transform: translate(-50px, 0);
}
.slide-right-leave-to {
  opacity: 0;
  transform: translate(50px, 0);
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}
</style>
